<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>{{ title ?? 'Список заявок с удаленной ИЧ' }}</template>
        <template #content>
          <AbstractTable
            ref="table"
            code="deleteInformationPartApplications"
            :default-data="defaultData"
            display-filter="menu"
          >
            <template #additionalButtons>
              <Button
                icon="pi pi-refresh"
                class="p-button-rounded p-button-outlined"
                @click="$refs.table.getData()"
              ></Button>
            </template>
            <template #id="row">
              <IdChip
                :id="row.data.id"
                toast-template='ID заявки с удаленной ИЧ "{v}" скопирован'
              />
            </template>
            <template #applicationUuid="row">
              <ValueChip
                :value="row.data.applicationUuid"
                toast-template='UUID заявки "{v}" скопирован'
              />
            </template>
            <template #cbUuid="row">
              <ValueChip
                v-if="row.data.cbUuid !== null"
                :value="row.data.cbUuid"
                toast-template='ЦБ УИД "{v}" скопирован'
              />
              <div v-if="row.data.cbUuid === null">-</div>
            </template>
          </AbstractTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable.vue";
import ValueChip from "@/components/Common/ValueChip.vue";
import IdChip from "@/components/Common/IdChip.vue";

export default {
  name: 'DeleteInformationPartApplicationsTable',
  components: {IdChip, ValueChip, AbstractTable},
  props: {
    title: {
      type: String,
      default: () => null
    },
    defaultData: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    refresh() {
      this.$refs.table.getData();
    }
  }
}
</script>


