import rpc from "@/rpc";

class InformationPartService {
    rpc

    constructor(rpc) {
        this.rpc = rpc
    }

    delete(applicationUuid, borrowerUuid, useOldFormat) {
        return this.rpc.execute('deleteInformationPart', {
            applicationUuid,
            borrowerUuid,
            useOldFormat
        })
    }

    resend(id) {
        return this.rpc.execute('resendDeleteInformationPart', {
            id
        })
    }

    get(id) {
        return this.rpc.execute('getDeleteInformationPart', {
            id
        })
    }
}

export default new InformationPartService(rpc)